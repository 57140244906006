
const digest = 'a7ff77c3cd9997831dc4c486519f980f8dd4d9e682c364bf05e5c702d07882a3';
const css = `._entry_73135_1 {
  padding: 0 0.15rem;
  width: calc(100% / 7);
}

._anchor_73135_6 {
  display: block;
  position: relative;
  top: -10rem;
  visibility: hidden;
}

@media only screen and (min-width: 960px) {
  ._entry_73135_1 {
    position: relative;
    padding: 0 0.25em;
    display: flex;
    align-items: center;
  }
}
@media only screen and (min-width: 1279px) {
  .minibar ._entry_73135_1 {
    position: relative;
    padding: 0 0.25em;
    display: flex;
    align-items: center;
    min-width: none;
    border-right: 1px solid var(--color--neutral-20);
    padding: 0.75em;
    width: 6em;
  }
}
@media only screen and (min-width: 1472px) {
  ._entry_73135_1 {
    position: relative;
    padding: 0 0.25em;
    display: flex;
    align-items: center;
    min-width: none;
    border-right: 1px solid var(--color--neutral-20);
    padding: 0.75em;
    width: 6em;
  }
  ._policyToolTipContainer_73135_44 {
    left: -24px;
  }
  ._tooltipContainer_73135_47 {
    left: -24px;
  }
}

._entry_73135_1 input {
  padding: 0.25rem !important;
}
@media only screen and (min-width: 960px) {
  ._entry_73135_1 input {
    padding: 0.75rem !important;
  }
}
input::selection {
  background: var(--project-color);
}

._entryField_73135_64 {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
._entryPopup_73135_71 {
  position: absolute;
  left: 0.5rem;
  z-index: 2;
  width: calc(100% - 1rem);
}
@media only screen and (min-width: 960px) {
  ._entryPopup_73135_71 {
    position: absolute;
    top: 75%;
    left: 0.5rem;
    z-index: 2;
    width: calc(100% - 1rem);
  }
}
._entryExpenseIcon_73135_86 {
  display: flex;
  position: absolute;
  top: 14px;
  left: 6px;
  font-size: 10px;
  color: var(--project-color);
  z-index: 1;
}
@media only screen and (min-width: 1279px) {
  .minibar ._entryExpenseIcon_73135_86 {
    top: 6px;
    left: 7px;
  }
}
@media only screen and (min-width: 1472px) {
  ._entryExpenseIcon_73135_86 {
    top: 6px;
    left: 7px;
  }
}
._entryNotesIcon_73135_107 {
  display: flex;
  position: absolute;
  bottom: 6px;
  left: 6px;
  z-index: 1;
  stroke: var(--project-color);
}
._entryWarningIcon_73135_115 {
  display: flex;
  position: absolute;
  bottom: 6px;
  left: 6px;
  z-index: 1;
}

@media only screen and (min-width: 1279px) {
  .minibar ._entryNotesIcon_73135_107 ._entryWarningIcon_73135_115 {
    bottom: 8px;
    left: 8px;
  }
}
@media only screen and (min-width: 1472px) {
  ._entryNotesIcon_73135_107 ._entryWarningIcon_73135_115 {
    bottom: 8px;
    left: 8px;
  }
}
._entryView_73135_135 {
  position: relative;
  display: flex;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 2.5;
  height: 2.5em;
  width: 100%;
  margin-right: 0.25em;
  margin-left: auto;
}
._entryViewNotesIcon_73135_147 {
  display: flex;
  position: absolute;
  bottom: 6px;
  left: 4px;
  left: 0;
  z-index: 1;
  stroke: var(--color-neutral-40);
}
._entryViewExpenseIcon_73135_156 {
  display: flex;
  position: absolute;
  top: 0;
  left: 4px;
  font-size: 10px;
  color: var(--color-neutral-40);
  z-index: 1;
}
._entryViewZero_73135_165 {
  color: var(--color-legacy-gray-3);
}

._entry_73135_1:last-child {
  border-right: none;
}

/* Overwrite Field default values */
._field--small_73135_174 {
  height: 2em;
}

._field__input_73135_178 {
  font-size: 0.875rem;
}

._toolTip_73135_182 {
  color: #fff;
  font-size: 12px;
  line-height: 14.4px;
  background-color: var(--color-neutral-30);
  margin-top: -0.5rem;
  border-radius: 6px;
  padding: 8px;
}

._policyToolTip_73135_44 {
  color: #000;
  font-size: 12px;
  line-height: 14.4px;
  background-color: #fff;
  margin-top: -0.5rem;
  border-radius: 6px;
  padding: 8px;
  box-shadow: 0px 2px 8px 0px rgba(1, 22, 39, 0.15);
}

._toolTipContainer_73135_203 {
  position: absolute;
  width: 123px;
  left: 0;
  animation: _slideUp_73135_1 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 1;
}

._policyToolTipContainer_73135_44 {
  position: absolute;
  width: 200px;
  animation: _slideUp_73135_1 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 1;
  left: -50px;
  top: 100%;
}

@media only screen and (min-width: 960px) {
  ._toolTipContainer_73135_203 {
    left: -50%;
  }
}

@media only screen and (min-width: 1279px) {
  ._toolTipContainer_73135_203 {
    left: -24px;
  }
}

._toolTipPointer_73135_232 {
  text-align: center;
}

@keyframes _slideUp_73135_1 {
  0% {
    opacity: 0;
    transform: translateY(4px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"entry":"_entry_73135_1","anchor":"_anchor_73135_6","policyToolTipContainer":"_policyToolTipContainer_73135_44","tooltipContainer":"_tooltipContainer_73135_47","entryField":"_entryField_73135_64","entryPopup":"_entryPopup_73135_71","entryExpenseIcon":"_entryExpenseIcon_73135_86","entryNotesIcon":"_entryNotesIcon_73135_107","entryWarningIcon":"_entryWarningIcon_73135_115","entryView":"_entryView_73135_135","entryViewNotesIcon":"_entryViewNotesIcon_73135_147","entryViewExpenseIcon":"_entryViewExpenseIcon_73135_156","entryViewZero":"_entryViewZero_73135_165","field--small":"_field--small_73135_174","fieldSmall":"_field--small_73135_174","field__input":"_field__input_73135_178","fieldInput":"_field__input_73135_178","toolTip":"_toolTip_73135_182","policyToolTip":"_policyToolTip_73135_44","toolTipContainer":"_toolTipContainer_73135_203","slideUp":"_slideUp_73135_1","toolTipPointer":"_toolTipPointer_73135_232"};
export { css, digest };
  